<template>
	<div>
		<div class="row mt-4 mb-4">
			<div class="col-sm-12 text-right">
				<h1>Catálogos</h1>
			</div>
		</div>

		<DataGrid :config="dataGridConfig" :data="catalogos" :select="seleccionar" @actions="dataGridActions">
			<div class="row">
				<div class="col-sm-2"><button class="btn principal-btn" @click="modal=1">Crear catálogo</button></div>
				<div class="col-sm-2"><button class="btn secondary-btn" @click="editar_catalogo">Editar catálogo</button></div>
				<div class="col-sm-2">
					<button class="btn warning-btn" @click="obtener_catalogos">Recargar</button>
				</div>
			</div>
		</DataGrid>

		<Modal v-if="modal != 0">
			<form @submit.prevent="guardar_catalogo">
				<div class="title">Cátalogos</div>
				<div class="body">
					<div class="row form-group">
						<label for="nombre" class="col-form-label col-sm-2">Nombre catálogo</label>
						<div class="col-sm-10"><input v-model="catalogo.nombre" type="text" name="nombre" id="nombre" class="form-control"></div>
					</div>
					<div class="row form-group">
						<label for="catalogo" class="col-form-label col-sm-2">Código catálogo</label>
						<div class="col-sm-10"><input v-model="catalogo.catalogo" type="text" name="catalogo" id="catalogo" class="form-control"></div>
					</div>
					<div class="row form-group">
						<label for="estatus" class="col-form-label col-sm-2">Estatus</label>
						<div class="col-sm-10">
							<select v-model="catalogo.estatus" name="estatus" id="estatus" class="form-control">
								<option value="1">Activo</option>
								<option value="0">Inactivo</option>
							</select>
						</div>
					</div>

					<table class="table-valores">
						<thead>
							<tr>
								<th>Opción</th>
								<th>Valor</th>
								<th>Código</th>
								<th>&nbsp;</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(opcion,index) in catalogo.opciones">
								<td>{{ opcion.nombre }}</td>
								<td>{{ opcion.valor }}</td>
								<td>{{ opcion.codigo }}</td>
								<td><span @click="editar_opcion(opcion, index)">Editar</span> | <span @click="eliminar_opcion_prev(opcion, index)">Eliminar</span></td>
							</tr>
						</tbody>
					</table>
					<div class="row mt-4">
						<div class="col-sm-2 offset-sm-10"><button type="button" class="btn secondary-btn" @click="modal_opcion=1">Agregar Opción</button></div>
					</div>
				</div>
				<div class="footer">
					<div class="row form-group">
						<div class="col-sm-2 offset-sm-8"><button class="btn secondary-btn">Guardar</button></div>
						<div class="col-sm-2"><button @click="cancelar_catalogo" type="button" class="btn danger-btn">Cancelar</button></div>
					</div>
				</div>
			</form>
		</Modal>

		<Modal v-if="modal_opcion != 0" :options="{width: '40vw'}">
			<form @submit.prevent="guardar_opcion">
				<div class="title">Opciones</div>
				<div class="body">
					<div class="row form-group">
						<label for="opcion.nombre" class="col-form-label col-sm-2">Nombre</label>
						<div class="col-sm-10"><input v-model="opcion.nombre" type="text" name="opcion.nombre" id="opcion.nombre" class="form-control"></div>
					</div>
					<div class="row form-group">
						<label for="opcion.valor" class="col-form-label col-sm-2">Valor</label>
						<div class="col-sm-10"><input v-model="opcion.valor" type="text" name="opcion.valor" id="opcion.valor" class="form-control"></div>
					</div>
					<div class="row form-group">
						<label for="opcion.codigo" class="col-form-label col-sm-2">Código</label>
						<div class="col-sm-10"><input v-model="opcion.codigo" type="text" name="opcion.codigo" id="opcion.codigo" class="form-control"></div>
					</div>
				</div>
				<div class="footer">
					<div class="row">
						<div class="col-sm-3 offset-sm-6"><button class="btn secondary-btn">Guardar</button></div>
						<div class="col-sm-3"><button type="button" class="btn danger-btn" @click="cancelar_opcion">Cancelar</button></div>
					</div>
				</div>
			</form>
		</Modal>

		<Modal v-if="opcion_eliminar" :options="{width: '30vw', type: 'error'}">
			<div class="title">Eliminar opción</div>
			<div class="body">
				<div class="row">
					<div class="col-sm-12 text-center">Realmente deseas eliminar la opción "{{ opcion_eliminar.nombre }}" </div>
				</div>
			</div>
			<div class="footer">
				<div class="row">
					<div class="col-sm-3 offset-sm-6"><button class="btn danger-btn" @click="eliminar_opcion">Elminar</button></div>
					<div class="col-sm-3"><button class="btn secondary-btn" @click="opcion_eliminar=null">Cancelar</button></div>
				</div>
			</div>
		</Modal>
	</div>
</template>

<script type="text/javascript">
	import DataGrid from '@/components/DataGridV2'
	import Modal from '@/components/Modal'

	import api from '@/apps/clientes/api/clientes'

	export default {
		components: {
			DataGrid, Modal
		}
		,data: function() {
			return {
				dataGridConfig: {
					name: 'catalogos'
					,cols: {
						catalogo: 'Catálogo'
						,nombre: 'Nombre'
						,estatus: 'Estatus'
					}
					,paginator: {
						pagina_actual: 1
						,total_registros: 1
						,registros_por_pagina: 20
					}
					,mutators: {
						estatus: function(val) {
							return val == 1 ? 'Activo' : 'Inactivo';
						}
					}
				}
				,dataGridConfigOpciones: {
					name: 'opciones'
					,cols: {
						opcion: 'Opción'
						,valor: 'Valor'
					}
					,paginator: {
						pagina_actual: 1
						,total_registros: 1
						,registros_por_pagina: 20
					}
					,mutators: {
						estatus: function() {
							return val == 1 ? 'Activo' : 'Inactivo';
						}
					}
				}
				,options: {
					page: 1
					,order_col: 'id'
					,order_dir: 'desc'
					,limit: 20
					,filters: []
				}
				,seleccionar: false
				,seleccionadas: []
				,catalogos: []
				,catalogo: {
					catalogo: null
					,estatus: 1
					,opciones: []
				}
				,modal: 0
				,opcion: {
					nombre: null
					,valor: null
				}
				,modal_opcion: 0
				,opcion_eliminar: null
			}
		}
		,methods: {
			obtener_catalogos: async function() {
				try {
					let res = (await api.catalogos_listar(this.options)).data;

					this.catalogos = res.data;
					this.dataGridConfig.paginator.pagina_actual = res.current_page;
					this.dataGridConfig.paginator.total_registros = res.total;
					this.dataGridConfig.paginator.registros_por_pagina = parseInt(res.per_page);
				}catch(e) {
					this.$log.info('Error', e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,dataGridActions: function(tipo, data) {
				// this.$log.info('tipo', tipo);
				if (tipo == 'options') {
					this.options = data;
					this.obtener_catalogos();
				}else {
					this.seleccionadas = data;
					this.seleccionar = true;
				}
			}
			,guardar_catalogo: async function() {
				try {
					let validacion = /[^a-z0-9\-\_]+/;
					if (validacion.test(this.catalogo.catalogo))
						return this.$helper.showMessage('Error','El código catálogo solo acepta letras minúsculas, números, guión medio ( - ) y guión bajo ( _ )','error','alert');

					if (this.catalogo.opciones.length == 0)
						return this.$helper.showMessage('Error','Tienes que agregar al menos una opción para el catálogo','error','alert');

					if (this.modal == 1) {
						let catalogo = (await api.catalogo_crear({catalogo: this.catalogo.catalogo, nombre: this.catalogo.nombre, estatus: this.catalogo.estatus})).data;

						this.catalogo.opciones.forEach(async opcion => {
							await api.catalogo_agregar_opcion(catalogo.id, opcion);
						});

						this.cancelar_catalogo();
						this.obtener_catalogos();
					}else {
						let catalogo = (await api.catalogo_editar(this.catalogo.id, this.catalogo)).data;

						this.catalogo.opciones.forEach(async opcion => {
							if (!opcion.id)
								await api.catalogo_agregar_opcion(catalogo.id, opcion);
							else
								await api.catalogo_editar_opcion(catalogo.id, opcion);
						});

						this.cancelar_catalogo();
						this.obtener_catalogos();
					}
				}catch(e) {
					this.seleccionadas = data;
					this.seleccionar = true;
				}
			}
			,editar_catalogo: async function() {
				if (this.seleccionadas.length == 0)
					return this.$helper.showMessage('Error','Tienes que seleccionar un catálogo a editar','error','alert');

				this.catalogo = (await api.catalogos_detalles(this.seleccionadas[0].id)).data;

				this.modal = 2;
			}
			,cancelar_catalogo: function() {
				this.catalogo = {
					catalogo: null
					,estatus: 1
					,opciones: []
				}
				this.modal = 0;
				this.seleccionar = false;
			}
			,guardar_opcion: function(index) {
				try {
					let validacion = /[^a-z0-9\-\_]/;
					if (validacion.test(this.opcion.valor))
						return this.$helper.showMessage('Error','El valor de la opción no puede tener caracteres especiales o espacios, solo números, letras y guión medio ( - ) y guión bajo ( _ )','error','alert');

					if (this.modal_opcion == 1) {
						this.catalogo.opciones.push(this.opcion);
						this.cancelar_opcion();
					}else {
						if (this.opcion.id) {
							for(let i=0; i<this.catalogo.opciones.length; i++) {
								if (this.catalogo.opciones[i].id == this.opcion.id) {
									this.catalogo.opciones[i] = this.opcion;
								}
							}
						}else {
							for(let i=0; i<this.catalogo.opciones.length; i++) {
								if (i == this.opcion.index) {
									this.catalogo.opciones[i] = this.opcion;
								}
							}
						}

						this.cancelar_opcion();
					}
				}catch(e) {
					this.$log.info('Error', e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,cancelar_opcion: function() {
				this.opcion = {
					nombre: null
					,valor: null
				}
				this.modal_opcion = 0;
			}
			,editar_opcion: function(opcion, index) {
				this.opcion = opcion;

				if (!this.opcion.id)
					this.opcion.index = index;

				this.modal_opcion = 2;
			}
			,eliminar_opcion_prev: function(opcion,index) {
				if (!opcion.id)
					opcion.index = index;

				this.opcion_eliminar = opcion;
			}
			,eliminar_opcion: async function() {
				if (this.opcion_eliminar.id) {
					await api.catalogo_eliminar_opcion(this.opcion_eliminar.catalogo_id, this.opcion_eliminar.id);

					this.editar_catalogo();
				}else {
					let opciones = [];
					for(let i=0; i<this.catalogo.opciones.length; i++) {
						if (i != this.opcion_eliminar.index)
							opciones.push(this.catalogo.opciones[i]);
					}

					this.catalogo.opciones = opciones;
				}

				this.opcion_eliminar = null;
			}
		}
	}
</script>

<style lang="scss">
	.table-valores {
		width: 100%;

		thead {
			tr {
				background-color: #333333;

				th {
					padding: 3px 8px;
				}

				th:nth-child(3) {
					width: 15%;
				}
			}
		}

		tbody {
			tr {
				td:last-child {
					text-align: center;
					padding: 3px 8px;
				}
			}

			tr:nth-child(2n+0) {
				background-color: #E6E6E6;
			}
		}
	}

	.icon-cross:before {
		content: "\ea0f";
	}
</style>